import React, { useState } from 'react';
import { config } from '../config';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import PopUp from './popup';
import { useSelector, useDispatch } from 'react-redux'; 
import { selectBasket, modifyBasket } from '../slices/basketSlice';

const ArticleDetail = (props) => {
    const basket = useSelector(selectBasket);
    const dispatch = useDispatch();

    const [quantity, setQuantity] = useState("");
    const [error, setError] = useState(null);
    const [isPopUp, setPopUp] = useState(false);

    const stockQuantity = parseInt(props.prod.quantity);
    const isProductOutOfStock = stockQuantity === 0;

    const onClickBasket = (oldBasket, newProduct) => {
        console.log('ajouté', quantity);
        let nbr = parseInt(quantity);

        if (isNaN(nbr)) {
            setError("Veuillez saisir un nombre svp!");
        } else if (isProductOutOfStock) {
            setError("Ce produit n'est plus disponible.");
        } else if (stockQuantity === 1 && nbr > 1) {
            setError("Il ne reste qu'un seul produit, vous ne pouvez en ajouter qu'un seul à votre panier.");
        } else if (stockQuantity < nbr) {
            setError(`Veuillez choisir une quantité inférieure ou égale à ${props.prod.quantity}`);
        } else {
            setError(null);

            let newBasket = JSON.parse(JSON.stringify(oldBasket));

            if (!Array.isArray(newBasket.basket)) {
                newBasket.basket = [];
            }

            let same = newBasket.basket.findIndex((b) => b.id === newProduct.id);
            if (same === -1) {
                let myProduct = JSON.parse(JSON.stringify(newProduct));
                myProduct.quantityInCart = parseInt(quantity);
                let myBasket = [...newBasket.basket, myProduct];
                let lsBasket = JSON.stringify({ basket: myBasket });
                window.localStorage.setItem('b4y-basket', lsBasket);
                dispatch(modifyBasket(myBasket));
            } else {
                newBasket.basket[same].quantityInCart += parseInt(quantity);
                let lsBasket = JSON.stringify(newBasket);
                window.localStorage.setItem('b4y-basket', lsBasket);
                dispatch(modifyBasket(newBasket.basket));
            }
            setPopUp(true);
        }
    };

    const getBeerMessage = (quantity) => {
        return quantity > 1 ? 'bières' : 'bière';
    };

    return (
        <li className="product-mosaic">
            {/*popup*/}
            <PopUp 
                isPopUp={isPopUp}
                msg={`Vous avez ajouté: ${quantity} ${getBeerMessage(quantity)} dans votre panier`}
                onClickClose={(e) => {
                    setPopUp(false);
                    setQuantity("");
                }}
            />
            {error !== null && <p>{error}</p>}
            <Link to={`/detail/${props.prod.id}`}>
                <div>
                    <h3>{props.prod.name}</h3>
                    <img src={config.pict_url + props.prod.photo} alt={props.prod.name} />
                    <p>{props.prod.description.substr(0,50)}</p>
                    <p>prix: {props.prod.price} €</p>
                    {/* Display availability message */}
                    {isProductOutOfStock && (
                        <p className="noProducts">Ce produit n'est plus disponible.</p>
                    )}
                </div>
            </Link>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <input type="text"
                    value={quantity}
                    onChange={(e) => {
                        setQuantity(e.currentTarget.value);
                    }}
                />
                <div className="addToBasket"
                    onClick={(e) => {
                        e.preventDefault();
                        onClickBasket(basket, props.prod);
                    }}
                >
                    <FontAwesomeIcon icon={faPlusCircle}/>
                </div>
            </form>
        </li>
    );
};

export default ArticleDetail;
