import React, {useState, useEffect} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import {checkPayment, updateOrder} from '../api/order'
import {Navigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux' 
import {selectUser} from '../slices/userSlice'
import {selectBasket, modifyBasket, cleanBasket} from '../slices/basketSlice'


const CheckoutForm = (props) =>{
    
    
    const [error, setError] = useState(false)
    const [redirectSuccess, setRedirectSuccess] = useState(false)
    const basket = useSelector(selectBasket)
    const user = useSelector(selectUser)
    const dispatch = useDispatch()
    
    const stripe = useStripe();
    const elements = useElements();
    
    //fonction de paiement lors de la validation de la CB
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
            setError("Problème avec le terminal de paiement");
            return;
        }
    
        const data = {
            email: user.infos.email,
            orderId: props.orderId,
        };
    
        try {
            const paymentAuth = await checkPayment(data);
            if (paymentAuth.status === 500) {
                setError("Echec du check de paiement");
                return;
            }
    
            const secret = paymentAuth.client_secret;
    
            const payment = await stripe.confirmCardPayment(secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        email: user.infos.email,
                    },
                },
            });
    
            if (payment.error) {
                setError("Echec de la tentative de paiement de votre CB");
            } else if (payment.paymentIntent.status === "succeeded") {
                console.log("Money is in the bank");
                const updateData = {
                    orderId: props.orderId,
                    status: "payed",
                };
                const updateResponse = await updateOrder(updateData);
                console.log('Update order response:', updateResponse);
                setRedirectSuccess(true);
            }
        } catch (error) {
            console.error(error);
            setError("Erreur lors de la gestion du paiement");
        }
    };
    
       
    
    

    if(redirectSuccess){
        return <Navigate to="/success" />
    }
    return (
        <section>
            {error !== null && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                        base: {
                          color: "#32325d",
                          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                          fontSmoothing: "antialiased",
                          fontSize: "16px",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#fa755a",
                          iconColor: "#fa755a",
                        },
                      },
                    }}
                        
                />
                <button
                    disabled={props.stripe}
                >Payer</button>
            </form>
        </section>
    ) 
}

export default CheckoutForm